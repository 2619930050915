
export const columnsValues = {
  expertAnswerId: 1,
  agencyName: 2,
  standardTitle: 3,
  expertRequirementAnswers: 4,
  expertRequirementAnswersWeight: 5,
  decision: 6,
  agencyCode: 7,
  donotApply: 8,
  finalDegree: 9,
  finalCommitmentLevelTitle: 10,
  finalCommitmentLevelFixedDegree: 11,
  selfEvaluationCommitmentLevelTitle: 12,
  selfEvaluationCommitmentLevelFixedDegree: 13,
  needVisit: 14,
  subExpertRequirementAnswers: 15,    
  decisionInWeight: 16,
  answerUserType: 17,
  relatedStandardProof: 18,
  attachmentCount: 19,
};

export const exportColumns = [
  {
    name: "كود اجابة الخبير",
    value: columnsValues.expertAnswerId,
  },
  {
    name: "اسم الجهة",
    value: columnsValues.agencyName,
  },
  {
    name: "كود الجهة",
    value: columnsValues.agencyCode,
  },
  {
    name: "المعيار",
    value: columnsValues.standardTitle,
  },
  {
    name: "يحتاج زيارة",
    value: columnsValues.needVisit,
  },
  {
    name: "الدرجة النهائية",
    value: columnsValues.finalDegree,
  },
  {
    name: "درجة الالتزام ( خبير )",
    value: columnsValues.finalCommitmentLevelTitle,
  },
  {
    name: "نتيجة درجة الالزام ( خبير )",
    value: columnsValues.finalCommitmentLevelFixedDegree,
  },
  {
    name: "الدرجة المقترحة من النظام ( خبير )",
    value: columnsValues.selfEvaluationCommitmentLevelTitle,
  },
  {
    name: "النتيجة المقترحة من النظام ( خبير )",
    value: columnsValues.selfEvaluationCommitmentLevelFixedDegree,
  },
  {
    name: "المتطلبات الاساسية والفرعية",
    value: columnsValues.expertRequirementAnswers,
    disabled: true,
  },
  {
    name: "الوزن",
    value: columnsValues.expertRequirementAnswersWeight,
  },
  {
    name: "متحقق ام لا",
    value: columnsValues.decision,
  },
  {
    name: "غير متطلب",
    value: columnsValues.donotApply,
  },
  {
    name: "المتطلبات الفرعية",
    value: columnsValues.subExpertRequirementAnswers,
  },
  {
    name: "القرار بالوزن",
    value: columnsValues.decisionInWeight,
  },
  {
    name: "نوع إجابة المستخدم",
    value: columnsValues.answerUserType,
  },
  {
    name: "المرفقات المتعلقة بالمعيار",
    value: columnsValues.relatedStandardProof,
  },
  {
    name: "عدد المرفقات",
    value: columnsValues.attachmentCount,
  },
];
